<template>
    <!-- Full Table -->
        <b-table-simple responsive bordered striped table-class="table-vcenter">
            <b-thead>
                <b-tr class="text-center">
                    <b-th>Nome</b-th>
                    <b-th>Cognome</b-th>
                    <b-th>Username</b-th>
                    <b-th>Email</b-th>
                    <b-th>Stato</b-th>
                    <b-th>Azioni</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="user in users" :key="user.id" class="text-center">
                    <b-td>
                        {{user.nome}}
                    </b-td>
                    <b-td>
                        {{user.cognome}}
                    </b-td>
                    <b-td>
                        {{user.username}}
                    </b-td>
                    <b-td>
                        {{user.email}}
                    </b-td>
                    <b-td>
                        <b-badge v-if="user.attivo" variant="success">Attivo</b-badge>
                        <b-badge v-else variant="danger">Disattivo</b-badge>
                    </b-td>
                    <b-td class="text-center">
                        <b-button size="sm" variant="secondary" @click="$bvModal.show('modal-edit-user-'+user.id)">
                            <i class="fa fa-fw fa-edit"></i>
                            Modifica
                        </b-button>
                    </b-td>

                    <!-- Modal Edit User -->
                    <modal-edit-user :user="user">
                    </modal-edit-user>
                    <!-- End Modal Edit User -->

                </b-tr>
            </b-tbody>
        </b-table-simple>
    <!-- END Full Table -->
</template>

<script>
    import ModalEditUser from "@/views/Users/ModalEditUser";
    export default {
        name: "UsersTable",
        components: {ModalEditUser},
        data() {
            return {}
        },

        props: {
            users: {
                required: true,
            },
            tableName: {
                required: true,
            },
        },
    }
</script>

<style scoped>

</style>
