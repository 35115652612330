<template>
    <!-- Full Table -->
    <base-block rounded title="File non assegnati" header-rtl header-class="bg-primary-darker" themed ref="fileTable">
        <template #options>
            <button type="button" class="btn-block-option"
                    @click="loadFiles()">
                <i class="si si-fw si-refresh"></i>
            </button>
        </template>
        <b-table-simple v-if="files && files.length > 0" responsive bordered striped table-class="table-vcenter">
            <b-thead>
                <b-tr class="text-center">
                    <b-th>Nome</b-th>
                    <b-th>Tipo</b-th>
                    <b-th>Dimensione</b-th>
                    <b-th>Assegna</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="file in files" :key="file.id" class="text-center">
                    <b-td>
                        {{file.nome}}
                    </b-td>
                    <b-td>
                        {{file.filemime}}
                    </b-td>
                    <b-td>
                        {{sizeHandler(file.size)}}
                    </b-td>
                    <b-td class="text-center">
                        <b-button v-if="getcurrentFolder" size="sm" variant="alt-primary" @click="assignFile(file)">
                            <i class="fa fa-fw fa-arrow-alt-circle-left"></i>
                            Assegna
                        </b-button>
                        <template v-else>Non puoi assegnare qui</template>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <p v-else class="text-center">
            Non ci sono nuovi file
        </p>
    </base-block>
    <!-- END Full Table -->
</template>

<script>
    import axios from "axios";
    import {EventBus} from "@/main";

    export default {
        name: "FileTable",

        data() {
            return {
                files: null,
            }
        },

        methods: {
            loadFiles() {
                var url = this.$store.state.server.ip + 'tree';
                var token = localStorage.getItem('token');

                return axios.get(url,
                    {
                        params: {
                            'notAssigned': true,
                        },

                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        this.files = response.data.data;
                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            },

            assignFile(file) {
                this.$refs.fileTable.stateLoading()
                var url = this.$store.state.server.ip + 'tree/' + file.id;
                var token = localStorage.getItem('token');

                axios.put(url, {
                    'file': true,
                    'parent_id': this.$store.state.folder.id,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        EventBus.$emit('assignedFile', this.$store.state.folder.id);
                        this.$bvModal.hide('modal-edit-file-' + file.id);
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });

            },

            sizeHandler(data) {
                let ret = null;
                if (data > 1 && data < 1000) {
                    ret = Math.trunc(data) + ' Byte';
                } else if ((data / 1024) > 1 && (data / 1024) < 1000) {
                    ret = Math.trunc((data / 1024)) + ' KB';
                } else if ((data / Math.pow(1024, 2)) > 1 && (data / Math.pow(1024, 2)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 2))) + ' MB';
                } else if ((data / Math.pow(1024, 3)) > 1 && (data / Math.pow(1024, 3)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 3))) + ' GB';
                } else if ((data / Math.pow(1024, 4)) > 1 && (data / Math.pow(1024, 4)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 4))) + ' TB';
                }
                return ret;
            },
        },

        created() {
            this.loadFiles();
        },

        mounted() {
            EventBus.$on("assignedFile", () => {
                this.loadFiles();
            });

            EventBus.$on('AssignedFileMoved', () => {
                this.timeout = setTimeout(() => {
                    this.$refs.fileTable.stateNormal();
                }, 2000);
            });

            EventBus.$on("deAssignedFile", () => {
                this.loadFiles();
                EventBus.$emit('deAssignedFileMoved');
            });

        },

        computed: {
            getcurrentFolder() {
                return this.$store.state.folder;
            },
        },

        destroyed() {
            clearTimeout(this.timeout);
        },
    }
</script>

<style scoped>

</style>