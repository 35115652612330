<template>
    <b-modal id="modal-create-user" size="lg" body-class="p-0" hide-footer hide-header>
        <div class="block block-rounded block-themed block-transparent mb-0">
            <b-form @submit.prevent="onSubmit">
                <div class="block-header bg-light">
                    <h3 class="block-title">Crea un nuovo utente</h3>
                    <div class="block-options">
                        <b-button type="button" variant="dark" size="sm" class="btn-block" @click="$bvModal.hide('modal-create-user')">
                            <i class="fa fa-fw fa-times"></i>
                        </b-button>
                    </div>
                </div>
                <div class="block-content font-size-sm">
                    <b-row>
                        <b-col md="6">
                            <div class="form-group">
                                <b-input-group prepend="Nome">
                                    <b-form-input v-model="$v.form.nome.$model"
                                                  :state="$v.form.nome.$dirty ? !$v.form.nome.$error : null"
                                                  id="nome" placeholder="Iserisci il nome">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="nome">
                                        Inserisci il nome
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <div class="form-group">
                                <b-input-group prepend="Cognome">
                                    <b-form-input v-model="$v.form.cognome.$model"
                                                  :state="$v.form.cognome.$dirty ? !$v.form.cognome.$error : null"
                                                  id="cognome" placeholder="Iserisci il cognome">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="cognome">
                                        Inserisci il cognome
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <div class="form-group">
                                <b-input-group prepend="Email">
                                    <b-form-input v-model="$v.form.email.$model"
                                                  :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                                                  id="email" placeholder="Inserisci l'email">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="email">
                                        Inserisci l'Email
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <div class="form-group">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button variant="primary" @click="passwordGenerator">Genera</b-button>
                                    </b-input-group-prepend>
                                    <b-form-input v-model="$v.form.password.$model"
                                                  :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                  id="password" readonly placeholder="Genera la password">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="password">
                                        Genera la password
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Tipo di utente">
                                <b-form-select id="tipo" v-model="$v.form.tipo.$model" :options="options"
                                               :state="$v.form.tipo.$dirty ? !$v.form.tipo.$error : null"
                                               plain>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Stato" label-class="font-w600">
                                <b-form-checkbox-group v-model="form.stato" switches stacked>
                                    <b-form-checkbox value="1">Attivo</b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="block-content block-content-full text-right border-top">
                    <b-button variant="alt-primary" class="mr-1"
                              @click="$bvModal.hide('modal-create-user')">Annulla
                    </b-button>
                    <b-button type="submit" variant="primary">Crea</b-button>
                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>

    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from "vuelidate";
    import axios from "axios";
    import {EventBus} from "@/main";
    import VueSweetalert2 from "vue-sweetalert2";
    import Vue from "vue";

    const options = {
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-success m-1',
            cancelButton: 'btn btn-danger m-1',
            input: 'form-control'
        }
    }

    // Register Vue SweetAlert2 with custom options
    Vue.use(VueSweetalert2, options)

    export default {
        mixins: [validationMixin],
        name: "ModalCreateUser",


        data() {
            return {
                form: {
                    nome: null,
                    cognome: null,
                    email: null,
                    tipo: null,
                    stato: ['1'],
                    password: null,
                },
                options: [
                    {value: 'ADMIN', text: 'Amministratore'},
                    {value: 'VIEWER', text: 'Visualizzatore'},
                    {value: 'AGENTE', text: 'Agente'},
                    {value: 'DISTRIBUTORE', text: 'Distributore'},
                    {value: 'CONCESSIONARIA', text: 'Concessionaria'},
                    {value: 'TOYO', text: 'Toyo'},
                    {value: 'TMP4', text: 'Tmp4'},
                    {value: 'NITTO', text: 'Nitto'},
                    {value: 'TOYOTMP4', text: 'ToyoTmp4'},
                    {value: 'TOYONITTO', text: 'ToyoNitto'},
                    {value: 'TMP4NITTO', text: 'Tmp4Nitto'},
                    {value: 'TOYOTMP4NITTO', text: 'ToyoTmp4Nitto'},
                ],
            }
        },

        validations: {
            form: {
                nome: {
                    required,
                },
                cognome: {
                    required,
                },
                email: {
                    required,
                },
                tipo: {
                    required
                },
                stato: {
                    required,
                },
                password: {
                    required,
                }

            }
        },

        methods: {
            onSubmit() {
                this.$v.form.$touch()

                if (this.$v.form.$anyError) {
                    return
                }

                //Form submit logic
                var url = this.$store.state.server.ip + 'users';
                var token = localStorage.getItem('token');

                axios.post(url, {
                    'nome': this.form.nome,
                    'cognome': this.form.cognome,
                    'email': this.form.email,
                    'password': this.form.password,
                    'tipo': this.form.tipo,
                    'attivo': this.form.stato[0],
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        EventBus.$emit('createdUser', this.form.tipo);
                        this.swalSuccess();
                        this.$bvModal.hide('modal-create-user')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        this.swalError();
                    });

            },

            passwordGenerator() {
                var length = 8,
                    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                    retVal = "";
                for (var i = 0, n = charset.length; i < length; ++i) {
                    retVal += charset.charAt(Math.floor(Math.random() * n));
                }
                this.form.password = retVal;
            },

            swalSuccess() {
                this.$swal('Success', 'Utente creato correttamente', 'success')
            },

            swalError() {
                this.$swal('Oops...', 'Problemi nella creazione utente', 'error')
            },
        },
    }
</script>

<style scoped>

</style>