<template>
    <div>

        <!-- Page Content -->
        <div class="content">
            <b-row v-if="user && user.tipo === 'ADMIN'">
                <b-col md="6">
                    <folder-explorer v-if="user.tipo" :permission="user.tipo"></folder-explorer>
                </b-col>
                <b-col md="6">
                    <file-table></file-table>
                </b-col>
            </b-row>
            <b-row v-else-if="user && user.tipo !== 'ADMIN'">
                <b-col md="8" offset-md="2">
                    <folder-explorer v-if="user.tipo" :permission="user.tipo"></folder-explorer>
                </b-col>
            </b-row>
        </div>
        <!-- END Page Content -->
    </div>
</template>

<script>

    import FileTable from "@/views/Tree/File/FileTable";
    import FolderExplorer from "@/views/Tree/Folder/FolderExplorer";

    export default {
        components: {FolderExplorer, FileTable},
        data() {
            return {
                user: null,
            }
        },

        methods: {
            checkLogin() {
                if (localStorage.getItem('user')) {
                    this.user = JSON.parse(localStorage.getItem('user'));
                    if (this.user.firstlogin === 1 && this.user.tipo !== 'ADMIN' && this.user.tipo !== 'VIEWER') {
                        this.$router.push('/disclaimer');
                    }
                } else {
                    localStorage.clear();
                    this.$router.push('/');
                }
            },
        },

        created() {

        },

        mounted() {
            this.checkLogin();
        },

    }
</script>
