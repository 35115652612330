<template>
    <div>

        <!-- Modal Create User -->
        <modal-create-user>
        </modal-create-user>
        <!-- End Modal Create User -->


        <!-- Page Content -->
        <div class="content">

            <!-- Block Tabs Default Style -->
            <b-tabs v-model="tab" class="block" nav-class="nav-tabs-block" content-class="block-content">
                <b-tab @click="tab = 0" title="Amministratori">
                    <users-table v-show="tab === 0" :users="users.ADMIN" table-name="Amministratori">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 1" title="Visualizzatori">
                    <users-table v-show="tab === 1" :users="users.VIEWER" table-name="Amministratori">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 2" title="Agenti">
                    <users-table v-show="tab === 2" :users="users.AGENTE" table-name="Agenti">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 3" title="Distributori">
                    <users-table v-show="tab === 3" :users="users.DISTRIBUTORE" table-name="Distributori">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 4" title="Concessionari">
                    <users-table v-show="tab === 4" :users="users.CONCESSIONARIA" table-name="Concessionari">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 5" title="Toyo">
                    <users-table v-if="tab === 5" :users="users.TOYO" table-name="Toyo">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 6" title="Tmp4">
                    <users-table v-show="tab === 6" :users="users.TMP4" table-name="Tmp4">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 7" title="Nitto">
                    <users-table v-show="tab === 7" :users="users.NITTO" table-name="Nitto">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 8" title="ToyoTmp4">
                    <users-table v-show="tab === 8" :users="users.TOYOTMP4" table-name="ToyoTmp4">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 9" title="ToyoNitto">
                    <users-table v-show="tab === 9" :users="users.TOYONITTO" table-name="ToyoNitto">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 10" title="Tmp4Nitto">
                    <users-table v-show="tab === 10" :users="users.TMP4NITTO" table-name="Tmp4Nitto">
                    </users-table>
                </b-tab>
                <b-tab @click="tab = 11" title="ToyoTmp4Nitto">
                    <users-table v-show="tab === 11" :users="users.TOYOTMP4NITTO" table-name="ToyoTmp4Nitto">
                    </users-table>
                </b-tab>
            </b-tabs>
            <!-- END Block Tabs Default Style -->
        </div>
        <!-- END Page Content -->
    </div>
</template>

<script>

    import axios from "axios";
    import UsersTable from "@/views/Users/UsersTable";
    import ModalCreateUser from "@/views/Users/ModalCreateUser";
    import {EventBus} from "@/main";

    export default {
        components: {ModalCreateUser, UsersTable},
        data() {
            return {
                users: [],
                tab: 0,
            }
        },

        methods: {
            async loadUsers(tipo = null) {
                var url = this.$store.state.server.ip + 'users';
                var token = localStorage.getItem('token');

                return axios.get(url,
                    {
                        params: {
                            'tipo': tipo,
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        if (tipo !== 'all') {
                            this.users[tipo] = Object.assign(response.data.data);
                        } else {
                            // let users=[];
                            // for(var key in response.data){
                            //     users[key] = response.data[key];
                            // }
                            this.users = response.data;
                        }
                        // console.log('users: ', this.users)

                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            }
        },

        async created() {
            this.loadUsers('all');
        },

        mounted() {
            EventBus.$on(["createdUser", "editedUser"], (data) => {
                data = 'all';
                this.loadUsers(data);
            });
        },

    }
</script>
