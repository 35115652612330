<template>
    <div>

        <!-- Page Content -->
        <div class="content">
            <base-block title="Termini di utilizzo" rounded header-bg>
                <p>Cliccando su Accetto si conferma l'accordo sul trattamento dei file ecc ecc</p>
                <div class="block-content block-content-full text-right border-top">
                    <b-button @click="acceptTerms(false)" class="text-right mr-1" variant="danger">Rifiuto</b-button>
                    <b-button @click="acceptTerms(true)" class="text-right" variant="primary">Accetto</b-button>
                </div>
            </base-block>
        </div>
        <!-- END Page Content -->
    </div>
</template>


<script>
    import axios from "axios";

    export default {
        name: "Disclaimer",

        data() {
            return {}
        },

        methods: {
            checkDisclaimer() {
                this.user = JSON.parse(localStorage.getItem('user'));
                if (this.user.firstlogin === 0 && this.user.tipo === 'ADMIN' && this.user.tipo === 'VIEWER') {
                    this.$router.push('/');
                }
            },

            acceptTerms(accepted = false) {

                var url;
                var token = localStorage.getItem('token');

                if(accepted === true) {

                    url = this.$store.state.server.ip + 'accept-terms-and-conditions';

                    axios.post(url, {
                        'accepted': accepted
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            console.log(response);
                            this.swalConfirm();
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });

                } else {

                    this.swalConfirmLogout();
                }

            },

            swalConfirm () {
                this.$swal({
                    title: '',
                    text: 'Riceverai la tua copia del documento alla tua mail',
                    icon: 'info',
                    showCancelButton: false,
                    customClass: {
                        confirmButton: 'btn btn-success m-1',
                    },
                    confirmButtonText: 'Ok',
                    html: false,
                    preConfirm: () => {
                        return new Promise(resolve => {
                            setTimeout(() => {
                                resolve()
                            }, 50)
                        })
                    }
                }).then(() => {
                    this.getUser();
                    this.$router.push('/dashboard').catch(()=>{})
                })
            },

            swalConfirmLogout () {
                this.$swal({
                    title: '',
                    text: 'Rifiutando verrai riportato al login',
                    icon: 'warning',
                    showCancelButton: true,
                    customClass: {
                        confirmButton: 'btn btn-danger m-1',
                        cancelButton: 'btn btn-secondary m-1'
                    },
                    confirmButtonText: 'Ok',
                    html: false,
                    preConfirm: () => {
                        return new Promise(resolve => {
                            setTimeout(() => {
                                resolve()
                            }, 50)
                        })
                    }
                }).then(result => {
                    if (result.value) {
                        this.logout();
                    }

                })
            },

            logout() {
                var url = this.$store.state.server.ip + 'logout';
                var token = localStorage.getItem('token');
                return axios.post(url, {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(() => {
                        localStorage.clear()
                        this.$router.push('/')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },

            getUser(){
                var url = this.$store.state.server.ip + 'get-user';
                var token = localStorage.getItem('token');
                axios.get(url,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        localStorage.setItem('user', JSON.stringify(response.data.data));
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

        },

        created() {
            if (localStorage.getItem('user')) {
                this.user = JSON.parse(localStorage.getItem('user'));
            } else {
                localStorage.clear()
                this.$router.push('/')
            }

            if(this.user.firstlogin === 0){
                this.$router.push('/dashboard');
            }
        },

        mounted() {

        },

    }
</script>

<style scoped>

</style>