<template>
    <!-- Page Content -->
    <div class="content">
        <deleted-file-explorer>
        </deleted-file-explorer>
    </div>
    <!-- END Page Content -->
</template>

<script>
    import DeletedFileExplorer from "@/views/Tree/Trash/DeletedFileExplorer";
    export default {
        name: "Trash",
        components: {DeletedFileExplorer}
    }
</script>

<style scoped>

</style>