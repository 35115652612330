<template>
    <div>
        <base-block rounded title=" " themed header-rtl ref="folderExplorer" class="block-mode-loading-hourglass">
            <template #options>
                <template v-if="backfolder.length > 1">
                    <button type="button" class="btn-block-option"
                            @click="loadFiles(backfolder[backfolder.length-2], true)">
                        <i class="far fa-fw fa-arrow-alt-circle-left"></i>
                    </button>
                    |
                </template>
                <template v-if="backfolder[backfolder.length-1] !== null">
                    <button type="button" class="btn-block-option"
                            @click="loadFiles('home', true)">
                        <i class="fa fa-fw fa-home"></i>
                    </button>
                    |
                </template>
                <template v-if="permission === 'ADMIN'">
                    <button type="button" class="btn-block-option"
                            v-b-modal.modal-create-folder>
                        <i class="fa fa-fw fa-folder-plus"></i>
                    </button>
                </template>
            </template>
            <ul class="list-group">
                <p class="text-center font-w600">
                    <template v-for="nome in backfolderName">{{nome}}/</template>
                </p>
                <!--                <p v-else class="text-center font-w600">Cartella principale</p>-->
                <li class="list-group-item" v-if="selezioneAll.length > 0">
                    <b-row>
                        <b-col>
                            <b-form-checkbox @change="selectAll"
                                             v-model="allSelected">Seleziona tutto
                            </b-form-checkbox>
                        </b-col>
                        <b-col class="text-right">
                            <b-button size="sm" variant="outline-success" class="mr-1"
                                      @click="download('allSelected')">
                                Scarica i selezionati
                                <i class="fa fa-fw fa-arrow-alt-circle-down"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </li>
                <template v-if="files && Object.keys(files).length !== 0">
                    <li v-for="file in files" :key="file.id" class="list-group-item">
                        <b-row>
                            <b-col v-if="file.type === 'file'" class="col-lg-1 col-md-8 col-sm-12 d-flex align-items-center">
                                <b-form-checkbox-group stacked class="float-left">
                                    <b-form-checkbox v-model="selezione" :value="file.id"
                                                     @change="select">
                                    </b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-col>
                            <b-col v-if="file.type === 'folder'" class="col-lg-6 col-md-8 col-sm-12">
                                <a class="link-fx font-w600"
                                   href="javascript:void(0)" @click="loadFiles(file.id)">
                                    <i class="fa fa-fw fa-folder"></i> {{file.nome}}
                                </a>
                            </b-col>
                            <b-col v-else class="col-lg-2 col-md-8 col-sm-12">
                                <a
                                   href="javascript:void(0)"
                                   @click="download(file)">
                                    <i v-if="file.filemime.includes('text')" class="fa fa-2x fa-fw fa-file-alt"></i>
                                    <i v-else-if="file.filemime.includes('application/vnd.rar') || file.filemime.includes('application/zip') || file.filemime.includes('application/x-7z-compressed')"
                                       class="fa fa-2x fa-fw fa-file-archive"></i>
                                    <i v-else-if="file.filemime.includes('application/vnd.ms-powerpoint') || file.filemime.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')"
                                       class="fa fa-2x fa-fw fa-file-powerpoint"></i>
                                    <i v-else-if="file.filemime.includes('application/vnd.ms-excel') || file.filemime.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
                                       class="fa fa-2x fa-fw fa-file-excel"></i>
                                    <i v-else-if="file.filemime.includes('application/msword') || file.filemime.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')"
                                       class="fa fa-2x fa-fw fa-file-word"></i>
                                    <i v-else-if="file.filemime.includes('application/pdf')"
                                       class="fa fa-2x fa-fw fa-file-pdf"></i>
                                    <i v-else-if="file.filemime.includes('application')"
                                       class="fa fa-2x fa-fw fa-file"></i>
                                    <i v-else-if="file.filemime.includes('audio')"
                                       class="fa fa-2x fa-fw fa-file-audio"></i>
                                    <img v-if="file.filemime.includes('image')" v-lazy="file.links3" width="100px"
                                         height="100px" class="rounded">
                                    <i v-else-if="file.filemime.includes('video')"
                                       class="fa fa-2x fa-fw fa-file-video"></i>
                                </a>


                            </b-col>
                            <b-col :class="(file.type === 'file') ? 'col-lg-9 col-md-4 col-sm-12 d-flex flex-column': 'col-lg-6 col-md-4 col-sm-12 d-flex flex-column'">
                                <div v-if="file.type === 'file'">
                                        {{file.nome}}
                                </div>
                                <div class="mt-auto">
                                    <div class=" text-right">
                                        <small>{{sizeHandler(file.size)}} </small>
                                        <b-button-group size="sm">
                                            <b-button v-if="file.type === 'folder' && permission === 'ADMIN'"
                                                      variant="alt-secondary"
                                                      @click="$bvModal.show('modal-edit-folder-'+file.id)"><i
                                                    class="fa fa-fw fa-pencil-alt"></i></b-button>
                                        </b-button-group>
                                        <template v-if="file.type === 'file'">
                                            <b-button-group size="sm">
                                                <b-button variant="alt-secondary" @click="download(file)"><i
                                                        class="fa fa-fw fa-arrow-alt-circle-down"></i></b-button>
                                                <b-button v-if="permission === 'ADMIN'" variant="alt-secondary"
                                                          @click="$bvModal.show('modal-edit-file-'+file.id)"><i
                                                        class="fa fa-fw fa-pencil-alt"></i></b-button>
                                                <b-button v-if="permission === 'ADMIN'" variant="alt-secondary"
                                                          @click="deAssignFile(file)"><i
                                                        class="fa fa-fw fa-arrow-alt-circle-right"></i></b-button>
                                            </b-button-group>
                                        </template>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- Modal Edit Folder -->
                        <modal-edit-folder v-if="file.type === 'folder'" :folder="file">
                        </modal-edit-folder>
                        <!-- END Modal Edit Folder -->

                        <!-- Modal Edit File -->
                        <modal-edit-file v-if="file.type === 'file'" :file="file">
                        </modal-edit-file>
                        <!-- END Modal Edit File -->

                    </li>
                </template>
                <li v-else class="text-center list-group-item">
                    Cartella vuota
                </li>
            </ul>
            <p></p>
        </base-block>

        <!-- Modal Create Folder -->
        <modal-create-folder :parent_id="backfolder[backfolder.length-1]">
        </modal-create-folder>
        <!-- END Modal Create Folder -->


    </div>
</template>

<script>
    import axios from "axios";
    import ModalCreateFolder from "@/views/Tree/Folder/ModalCreateFolder";
    import {EventBus} from "@/main";
    import ModalEditFolder from "@/views/Tree/Folder/ModalEditFolder";
    import ModalEditFile from "@/views/Tree/File/ModalEditFile";
    import JSZip from "jszip";
    import JSZipUtils from 'jszip-utils';
    import {saveAs} from 'file-saver';
    import Vue from 'vue'
    import VueLazyload from 'vue-lazyload'

    Vue.use(VueLazyload)

    export default {
        name: "FolderExplorer",
        components: {ModalEditFile, ModalEditFolder, ModalCreateFolder},
        data() {
            return {
                files: null,
                parentFolder: null,
                backfolder: [],
                backfolderName: [],
                current_folder_id: null,
                selezione: [],
                selezioneAll: [],

                allSelected: false,
            }
        },

        props: {
            permission: {
                required: true,
            }
        },

        created() {
            this.loadFiles();
        },

        mounted() {

            EventBus.$on(["createdFolder", "editedFolder", "deletedFolder", "editedFile", "deletedFile", "assignedFile", "deAssignedFile"], (data) => {
                this.loadFiles(data);
                EventBus.$emit('AssignedFileMoved');
            });

            EventBus.$on('deAssignedFileMoved', () => {
                setTimeout(() => {
                    this.$refs.folderExplorer.stateNormal();
                }, 2000);
            });

        },

        methods: {

            download(file) {
                this.swalInfo();
                this.$refs.folderExplorer.stateLoading();
                var data = [];
                if (file === 'all') {
                    data = this.files.filter(file => (file.type === 'file')).map(function (file) {
                        return file
                    });
                } else if (file === 'allSelected') {
                    const files = this.files;
                    this.selezione.forEach(function (sel) {
                        files.forEach(function (file) {
                            if (file.id === sel) {
                                data.push(file);
                            }
                        })
                    })

                } else {
                    data.push(file);
                }

                if (data.length > 1) {

                    var zip = new JSZip();
                    var count = 0;
                    var zipFilename = "ToyoFiles.zip";

                    var urls = data;

                    urls.forEach(function (url) {
                        var filename = url.nome;
                        // loading a file and add it in a zip file

                        JSZipUtils.getBinaryContent(url.link, function (err, data) {
                            if (err) {
                                throw err; // or handle the error
                            }
                            zip.file(filename, data, {binary: true});
                            count++;
                            if (count == urls.length) {
                                zip.generateAsync({type: 'blob'}).then(function (content) {
                                    saveAs(content, zipFilename);
                                });
                            }
                        });
                    });
                } else {
                    data.forEach(function (file) {
                        axios({
                            url: file.link,
                            method: 'GET',
                            responseType: 'blob',
                        }).then((response) => {
                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            var nomeDownloadFile = file.nome === file.file_nome ? file.nome : file.nome + '.' + file.format;
                            fileLink.setAttribute('download', nomeDownloadFile);
                            document.body.appendChild(fileLink);

                            fileLink.click();
                        });
                    });
                }
                this.$refs.folderExplorer.stateNormal();
            },

            loadFiles(parent_id = null, back = false) {
                var url = null;
                if (parent_id != null && parent_id !== 'home') {
                    url = this.$store.state.server.ip + 'tree/' + parent_id;
                } else {
                    if (parent_id === 'home') {
                        this.backfolder = [];
                        this.backfolder.push(null);

                        this.backfolderName = [];
                        this.backfolderName.push('Cartella Principale');
                        parent_id = null;
                    }
                    url = this.$store.state.server.ip + 'tree';
                }
                var token = localStorage.getItem('token');
                return axios.get(url,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        if (back === true) {
                            this.backfolder.pop();
                            this.backfolderName.pop();
                            if (this.backfolder.length < 1) {
                                this.backfolder.push(null);
                                this.backfolderName.push('Cartella Principale');
                            }
                        } else if (parent_id !== this.backfolder[this.backfolder.length - 1]) {
                            this.backfolder.push(parent_id);
                            if (response.data.parent_folder) {
                                this.backfolderName.push(response.data.parent_folder.nome);

                            } else {
                                this.backfolderName.push('Cartella Principale');
                            }

                        }
                        this.files = response.data.data;
                        this.parentFolder = response.data.parent_folder;
                        this.$store.state.folder = this.parentFolder;
                        this.makeSelectAllArray(this.files);
                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            },

            deAssignFile(file) {
                this.$refs.folderExplorer.stateLoading();
                var url = this.$store.state.server.ip + 'tree/' + file.id;
                var token = localStorage.getItem('token');

                axios.put(url, {
                    'file': true,
                    'deassign': true,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        EventBus.$emit('deAssignedFile', file.parent_id);
                        this.$bvModal.hide('modal-edit-file-' + file.id);
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });

            },

            selectAll() {

                this.selezione = [];

                if (this.allSelected) {
                    this.selezione = this.selezioneAll;
                }

            },

            select() {
                this.allSelected = false;
            },

            makeSelectAllArray(files) {
                this.selezioneAll = files.filter(file => (file.type === 'file')).map(function (file) {
                    return file.id
                });
            },

            sizeHandler(data) {
                let ret = null;
                if (data > 1 && data < 1000) {
                    ret = Math.trunc(data) + ' Byte';
                } else if ((data / 1024) > 1 && (data / 1024) < 1000) {
                    ret = Math.trunc((data / 1024)) + ' KB';
                } else if ((data / Math.pow(1024, 2)) > 1 && (data / Math.pow(1024, 2)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 2))) + ' MB';
                } else if ((data / Math.pow(1024, 3)) > 1 && (data / Math.pow(1024, 3)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 3))) + ' GB';
                } else if ((data / Math.pow(1024, 4)) > 1 && (data / Math.pow(1024, 4)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 4))) + ' TB';
                }
                return ret;
            },

            swalInfo() {
                this.$swal('Success', 'Download iniziato', 'info')
            },


        },

    }
</script>

<style scoped>

</style>