<template>
    <div>
        <base-block rounded title="Cartelle e file eliminati" header-rtl ref="folderExplorer"
                    class="block-mode-loading-hourglass">
            <ul class="list-group">
                <li v-for="file in files" :key="file.id" class="list-group-item">
                    <b-row>
                        <b-col class="col-lg-8 col-md-8 col-sm-12">

                            <a v-if="file.type !== 'folder'" href="javascript:void(0)"
                               @click="download(file)">
                                <i v-if="file.filemime.includes('text')" class="fa fa-fw fa-file-alt"></i>
                                <i v-else-if="file.filemime.includes('application/vnd.rar') || file.filemime.includes('application/zip') || file.filemime.includes('application/x-7z-compressed')"
                                   class="fa fa-fw fa-file-archive"></i>
                                <i v-else-if="file.filemime.includes('application/vnd.ms-powerpoint') || file.filemime.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')"
                                   class="fa fa-fw fa-file-powerpoint"></i>
                                <i v-else-if="file.filemime.includes('application/vnd.ms-excel') || file.filemime.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
                                   class="fa fa-fw fa-file-excel"></i>
                                <i v-else-if="file.filemime.includes('application/msword') || file.filemime.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')"
                                   class="fa fa-fw fa-file-word"></i>
                                <i v-else-if="file.filemime.includes('application/pdf')"
                                   class="fa fa-fw fa-file-pdf"></i>
                                <i v-else-if="file.filemime.includes('application')"
                                   class="fa fa-fw fa-file"></i>
                                <i v-else-if="file.filemime.includes('audio')"
                                   class="fa fa-fw fa-file-audio"></i>
                                <i v-else-if="file.filemime.includes('image')"
                                   class="fa fa-fw fa-file-image"></i>
                                <i v-else-if="file.filemime.includes('video')"
                                   class="fa fa-fw fa-file-video"></i>
                                {{file.nome}}
                            </a>
                            <a v-else>
                                <i class="fa fa-fw fa-folder"></i>
                                {{file.nome}}
                            </a>

                        </b-col>
                        <b-col class="text-right col-lg-4 col-md-4 col-sm-12">
                            <small>{{sizeHandler(file.size)}} </small>

                            <b-button-group size="sm">
                                <b-button v-if="file.type === 'file'" variant="outline-success"
                                          class="mr-1"
                                          @click="download(file)">
                                    <i class="fa fa-fw fa-arrow-alt-circle-down"></i>
                                </b-button>
                                <b-button variant="alt-secondary"
                                          @click="restore(file)">
                                    <i class="fa fa-fw fa-trash-restore"></i>
                                </b-button>
                                <b-button v-if="file.type === 'file'" variant="alt-secondary"
                                          class="mr-1"
                                          @click="$bvModal.show('modal-edit-file-'+file.id)">
                                    <i class="fa fa-fw fa-pencil-alt"></i>
                                </b-button>
                                <b-button v-else-if="file.type === 'folder'" variant="alt-secondary"
                                          class="mr-1"
                                          @click="$bvModal.show('modal-edit-folder-'+file.id)">
                                    <i class="fa fa-fw fa-pencil-alt"></i>
                                </b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>

                    <!-- Modal Edit Folder -->
                    <modal-edit-folder v-if="file.type === 'folder'" :folder="file">
                    </modal-edit-folder>
                    <!-- END Modal Edit Folder -->

                    <!-- Modal Edit File -->
                    <modal-edit-file v-if="file.type === 'file'" :file="file">
                    </modal-edit-file>
                    <!-- END Modal Edit File -->

                </li>
                <p></p>
                <li v-if="files && files.length === 0" class="text-center list-group-item">
                    Il cestino è vuoto
                </li>
            </ul>
            <p></p>
        </base-block>

    </div>
</template>

<script>
    import axios from "axios";
    import ModalEditFolder from "@/views/Tree/Folder/ModalEditFolder";
    import ModalEditFile from "@/views/Tree/File/ModalEditFile";
    import {EventBus} from "@/main";

    export default {
        name: "DeletedFileExplorer",
        components: {ModalEditFile, ModalEditFolder},
        data() {
            return {
                files: null,
            }
        },

        created() {
            this.loadFiles();
        },

        mounted() {
            EventBus.$on(["restoredFileFolder", "deletedFolder", "deletedFile", "deletedFolderDefinitely"], () => {
                this.loadFiles();
            });
        },

        methods: {

            download(file) {
                this.$refs.folderExplorer.stateLoading();
                var data = [];
                if (file === 'all') {
                    data = this.files.filter(file => (file.type === 'file')).map(function (file) {
                        return file
                    });
                } else if (file === 'allSelected') {
                    const files = this.files;
                    this.selezione.forEach(function (sel) {
                        files.forEach(function (file) {
                            if (file.id === sel) {
                                data.push(file);
                            }
                        })
                    })

                } else {
                    data.push(file);
                }
                data.forEach(function (file) {
                    axios({
                        url: file.link,
                        method: 'GET',
                        responseType: 'blob',
                    }).then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        var nomeDownloadFile = file.nome === file.file_nome ? file.nome : file.nome + '.' + file.format;
                        fileLink.setAttribute('download', nomeDownloadFile);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
                });

                this.$refs.folderExplorer.stateNormal();
            },

            loadFiles() {
                var url = this.$store.state.server.ip + 'tree';
                var token = localStorage.getItem('token');

                return axios.get(url,
                    {
                        params: {
                            'trash': true,
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        this.files = response.data.data;
                    })
                    .catch(error => {
                        console.log(error.response)
                    });

            },

            restore(file) {
                // Form submit logic
                var url = this.$store.state.server.ip + 'tree/' + file.id;
                var token = localStorage.getItem('token');

                axios.put(url, {
                    'restore': true,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        EventBus.$emit('restoredFileFolder', file.id);
                        this.swalSuccess();
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        this.swalError();
                    });
            },

            sizeHandler(data) {
                let ret = null;
                if (data > 1 && data < 1000) {
                    ret = Math.trunc(data) + ' Byte';
                } else if ((data / 1024) > 1 && (data / 1024) < 1000) {
                    ret = Math.trunc((data / 1024)) + ' KB';
                } else if ((data / Math.pow(1024, 2)) > 1 && (data / Math.pow(1024, 2)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 2))) + ' MB';
                } else if ((data / Math.pow(1024, 3)) > 1 && (data / Math.pow(1024, 3)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 3))) + ' GB';
                } else if ((data / Math.pow(1024, 4)) > 1 && (data / Math.pow(1024, 4)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 4))) + ' TB';
                }
                return ret;
            },

            swalSuccess() {
                this.$swal('Success', 'File ripristinato', 'success')
            },

            swalError() {
                this.$swal('Oops...', 'Problemi con l\'eliminazione', 'error')
            },
        }
    }
</script>

<style scoped>

</style>