<template>
    <b-modal id="modal-create-folder" size="lg" body-class="p-0" hide-footer hide-header>
        <div class="block block-rounded block-themed block-transparent mb-0">
            <b-form @submit.prevent="onSubmit">
                <div class="block-header">
                    <h3 class="block-title">Crea cartella</h3>
                    <div class="block-options">
                        <button type="button" class="btn-block-option" @click="$bvModal.hide('modal-create-folder')">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="block-content font-size-sm">
                    <b-form-group label="Nome" label-for="example-text-input">
                        <b-form-input v-model="$v.form.nome.$model"
                                      :state="$v.form.nome.$dirty ? !$v.form.nome.$error : null"
                                      id="nome" placeholder="Nome cartella">
                        </b-form-input>
                        <b-form-invalid-feedback id="nome">
                            Inserisci il nome della cartella
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="Chi puo accedervi?" label-class="font-w600">
                        <b-form-checkbox-group v-model="$v.form.permessi.$model" id="permessi"
                                               :state="$v.form.permessi.$dirty ? !$v.form.permessi.$error : null"
                                               stacked>
                            <b-row>
                                <b-col class="col-lg-3 col-md-8 col-sm-12">
                                    <b-form-checkbox value="AGENTE">Agenti</b-form-checkbox>
                                    <b-form-checkbox value="DISTRIBUTORE">Distributori</b-form-checkbox>
                                    <b-form-checkbox value="CONCESSIONARIA">Concessionari</b-form-checkbox>
                                </b-col>
                                <b-col class="col-lg-3 col-md-8 col-sm-12">
                                    <b-form-checkbox value="TOYO">Toyo</b-form-checkbox>
                                    <b-form-checkbox value="TMP4">Tmp4</b-form-checkbox>
                                    <b-form-checkbox value="NITTO">Nitto</b-form-checkbox>
                                </b-col>
                                <b-col class="col-lg-3 col-md-8 col-sm-12">
                                    <b-form-checkbox value="TOYOTMP4">ToyoTmp4</b-form-checkbox>
                                    <b-form-checkbox value="TOYONITTO">ToyoNitto</b-form-checkbox>
                                    <b-form-checkbox value="TMP4NITTO">Tmp4Nitto</b-form-checkbox>
                                </b-col>
                                <b-col class="col-lg-3 col-md-8 col-sm-12">
                                    <b-form-checkbox value="TOYOTMP4NITTO">ToyoTmp4Nitto</b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form-checkbox-group>
                    </b-form-group>
                </div>
                <div class="block-content block-content-full text-right border-top">
                    <b-button variant="alt-primary" class="mr-1"
                              @click="$bvModal.hide('modal-create-folder')">Annulla
                    </b-button>
                    <b-button type="submit" variant="primary">Crea</b-button>
                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>

    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from "vuelidate";
    import axios from "axios";
    import {EventBus} from "@/main";
    import VueSweetalert2 from "vue-sweetalert2";
    import Vue from "vue";

    const options = {
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-success m-1',
            cancelButton: 'btn btn-danger m-1',
            input: 'form-control'
        }
    }

    // Register Vue SweetAlert2 with custom options
    Vue.use(VueSweetalert2, options)

    export default {
        mixins: [validationMixin],
        name: "ModalCreateFolder",

        props: {
            parent_id: {
                required: true,
            }
        },

        data() {
            return {
                form: {
                    nome: null,
                    permessi: [],
                },

            }
        },

        validations: {
            form: {
                nome: {
                    required,
                },
                permessi: {
                    required
                },
            },
        },

        methods: {
            onSubmit() {
                this.$v.form.$touch()

                if (this.$v.form.$anyError) {
                    return
                }

                // Form submit logic
                var url = this.$store.state.server.ip + 'tree';
                var token = localStorage.getItem('token');

                axios.post(url, {
                    'parent_id': this.parent_id,
                    'nome': this.form.nome,
                    'tipo_accesso': this.form.permessi,
                },  {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        EventBus.$emit('createdFolder', this.parent_id);
                        this.swalSuccess();
                        this.$bvModal.hide('modal-create-folder')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        this.swalError();
                    });

            },

            swalSuccess() {
                this.$swal('Success', 'Salavataggio completato', 'success')
            },

            swalError() {
                this.$swal('Oops...', 'Problemi nel salvataggio', 'error')
            },
        }
    }
</script>

<style scoped>

</style>